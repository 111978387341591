/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useEffect, useState, memo } from "react";
import { useLiveAPIContext } from "../../contexts/LiveAPIContext";
import styled from 'styled-components';

const TypeWriterContainer = styled.div`
  font-family: 'google sans', monospace;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  color: white;
  height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
  // background: #000;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('${process.env.PUBLIC_URL}/background.png');
    background-size: 100px 100px;
    background-position: center;
    background-repeat: repeat;
    opacity: 0.4;
    z-index: -2;
    border-radius: 8px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
    border-radius: 8px;
  }
`;

const Header = styled.h1`
  font-size: 4rem;
  background: linear-gradient(
    45deg,
    #9c27b0,
    #ff69b4,
    #ff1493,
    #9400d3,
    #9c27b0
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
  flex-shrink: 0;
  animation: gradient 5s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const TypeWriterText = styled.div`
  white-space: pre-wrap;
  line-height: 2;
  opacity: 0.9;
  font-size: 1.25rem;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Cursor = styled.span`
  display: inline-block;
  width: 0.5em;
  height: 1.2em;
  background-color: white;
  margin-left: 2px;
  animation: blink 1s step-end infinite;

  @keyframes blink {
    from, to { opacity: 1; }
    50% { opacity: 0; }
  }
`;

const welcomeMessages = [
  "Your safe space to practice difficult conversations...",
  "Ready to help you master:",
  "• Salary negotiations",
  "• Performance reviews",
  "• Conflict resolution",
  "• Personal conversations",
  "\nLet's begin your practice session..."
];

function TypeWriterComponent() {
  const [displayText, setDisplayText] = useState<string>("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const { setConfig } = useLiveAPIContext();

  useEffect(() => {
    setConfig({
      model: "models/gemini-2.0-flash-exp",
      generationConfig: {
        responseModalities: "audio",
        speechConfig: {
          voiceConfig: { prebuiltVoiceConfig: { voiceName: "Aoede" } },
        },
      },
      systemInstruction: {
        parts: [
          {
            text: `You are an advanced conversation simulator and coach, designed to help people prepare for challenging interactions. You can:

1. SIMULATE DIFFERENT PERSONALITIES:
- Switch between various challenging personalities (aggressive, dismissive, manipulative)
- Replicate realistic workplace conflicts and tough situations
- Suddenly change moods or tactics like real people do

2. CONDUCT REALISTIC ROLE-PLAY:
- Interrupt and challenge the user's points
- Use tough questioning techniques
- Show emotional reactions (frustration, skepticism, hostility)
- Push back on weak arguments
- Create unexpected scenarios

3. PROVIDE IMMEDIATE FEEDBACK:
- Point out communication weaknesses
- Suggest better responses
- Highlight missed opportunities
- Show alternative approaches

Switch between being:
- The tough conversation partner (to practice with)
- The coach (to provide feedback)
- The analyst (to explain dynamics)

Don't hold back - create realistic pressure while maintaining a safe practice environment.`,
          },
        ],
      },
      tools: [
        { googleSearch: {} }
      ],
    });
  }, [setConfig]);

  useEffect(() => {
    if (messageIndex >= welcomeMessages.length) {
      const resetTimer = setTimeout(() => {
        setDisplayText("");
        setMessageIndex(0);
        setCharIndex(0);
      }, 2000);
      return () => clearTimeout(resetTimer);
    }

    const currentMessage = welcomeMessages[messageIndex];
    if (charIndex < currentMessage.length) {
      const timer = setTimeout(() => {
        setDisplayText(prev => prev + currentMessage[charIndex]);
        setCharIndex(prev => prev + 1);
      }, 50);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setMessageIndex(prev => prev + 1);
        setCharIndex(0);
        setDisplayText(prev => prev + "\n");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [messageIndex, charIndex]);

  return (
    <TypeWriterContainer>
      <Header>Welcome to Tough Tongue AI!</Header>
      <TypeWriterText>
        {displayText}
        <Cursor />
      </TypeWriterText>
    </TypeWriterContainer>
  );
}

export const Altair = memo(TypeWriterComponent);
